<template>
  <tr>
    <td :class="{ 'is-deleted': item.isDeleted }">
      <DateFormat :value="item.date" data-testid="insulin-dose-date" />
    </td>
    <template v-if="doseTaken">
      <td :class="{ 'is-deleted': item.isDeleted }">
        <InsulinDiaryDoseTime
          :doseTime="item.time"
          :hourFormatString="hourFormatString"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
      <td v-if="anyBolusTimepoint" :class="{ 'is-deleted': item.isDeleted }">
        {{ $t(`time-point-short.${item.timepoint}`) }}
      </td>
      <td class="text-right" :class="{ 'is-deleted': item.isDeleted }">
        <InsulinDiaryDoseValue
          :dose="item"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
      <td
        v-if="!hideCarbsConsumed"
        class="text-right"
        :class="{ 'is-deleted': item.isDeleted }"
      >
        <InsulinDiaryCarbsConsumed
          :carbsConsumed="item.carbsConsumed"
          :carbsConsumedType="item.carbsConsumedType"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
    </template>
    <td v-else :colspan="colSpan" :class="{ 'is-deleted': item.isDeleted }">
      <v-chip
        label
        small
        :color="labelColor"
        text-color="#333"
        class="width-100-pct align-center justify-center"
        data-testid="bmg-chip-msg"
      >
        <span
          :class="{
            'deleted-dose-color': item.isDeleted,
          }"
        >
          {{ doseLabel }}
        </span>
      </v-chip>
    </td>
    <td>
      <small data-testid="item-modified-date-time">
        <DateFormat
          :value="item.modifiedAt.time"
          format="dd-MMM-yyyy"
          data-testid="item-modified-date"
          class="d-block"
        />
        <DateFormat
          :value="item.modifiedAt.time"
          format="HH:mm:ss"
          data-testid="item-modified-time"
        />
      </small>
    </td>
    <td>
      <small>
        <span class="d-block">
          <VersionBy :versionBy="item.versionBy" />
        </span>
        <span>{{ item.versionReason }}</span>
      </small>
    </td>
  </tr>
</template>

<script>
import { format24h } from '@/utils/date/hourFormatter'

import DateFormat from '../DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'
import InsulinListMixin from './InsulinListMixin'

export default {
  name: 'InsulinAuditRow',
  mixins: [InsulinListMixin],
  components: {
    DateFormat,
    InsulinDiaryCarbsConsumed: () => import('./_InsulinDiaryCarbsConsumed.vue'),
    InsulinDiaryDoseTime: () => import('./_InsulinDiaryDoseTime.vue'),
    InsulinDiaryDoseValue: () => import('./_InsulinDiaryDoseValue.vue'),
    VersionBy,
  },
  props: {
    anyBolusTimepoint: { type: Boolean, required: false, default: false },
    hideCarbsConsumed: { type: Boolean, required: false, default: false },
    hourFormatString: { type: String, required: true, default: format24h },
    landscape: { type: Boolean, required: true },
    previousItem: { type: Object, default: null },
  },
  computed: {
    hasDoseValue() {
      return (
        this.item.actualDoseType && this.item.actualDoseType === 'specified'
      )
    },
    carbsSpecified() {
      return (
        this.item.carbsConsumedType &&
        this.item.carbsConsumedType === 'specified'
      )
    },
    carbsMissing() {
      return (
        this.item.carbsConsumedType && this.item.carbsConsumedType === 'idk'
      )
    },
    colSpan() {
      let colSpan = 2

      if (!this.hideCarbsConsumed) {
        colSpan += 1
      }

      if (this.anyBolusTimepoint) {
        colSpan += 1
      }

      return colSpan
    },
  },
}
</script>
